.logo-wrap.components-top {
  float: left;
}
.user-wrap.components-top {
  float: right;
  font-size: 18px;
  .btn-group {
    cursor: pointer;
    float: left;
    margin: 0 15px;
  }
}
