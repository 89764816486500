.login {
  background-color: #fff;
  .container {
    width: 1200px;
    margin: 0 auto;
  }
  // 顶部区域排版
  .top {
    border-bottom: 2px solid #323232;//#f2f5f0;
    .logo-wrap {
      overflow: hidden;
      background-color: #fff;
      .logo {
        display: block;
        width: 334px;
        height: 34px;
        margin-top: 11px;
        background: url('/images/logo.png') no-repeat;
        background-size: 100% 100%;
      }
    }
  }

  // 中间登录区域排版
  $bg-color: #eff2f7;
  .main-bd {
    padding-bottom: 134px;
    background-color: #323232;// #fff;
    .login-box-wrap {
      box-sizing: border-box;
      height: 528px;
      background-color: $bg-color;
      padding: 89px 0 32px;
      &::after {
        content: ' ';
        display: block;
        height: 0px;
        border: 67px solid $bg-color;
        border-width: 67px 50vw 0;
        border-color: $bg-color transparent;
        // background-color: red;
        position: relative;
        top: 32px;
      }
      .login-box {
        background-color: #323232;//#fff;
        border: 1px solid #f7f8fa;
        border-radius: 5px;
        margin: 0 auto;
        height: 405px;
        box-sizing: border-box;
        padding: 49px 15px;
        display: flex;
        text-align: center;
        .login-group {
          flex: 0 0 695px;
          border-right: 1px solid #fff;//#323232;//#ccc;
          .input-group {
            display: block;
            height: 38px;
            box-sizing: border-box;
            border: 2px solid #D7E0EF;
            width: 306px;
            margin: 20px auto;
            border-radius: 4px;
            position: relative;
            img {
              position: absolute;
              left: 10px;
              top: 4px;
            }
            input::-webkit-input-placeholder {
              color: #B3B3B3;
            }
            input {
              display: block;
              width: 100%;
              box-sizing: border-box;
              border: none;
              outline: none;
              height: 34px;
              line-height: 34px;
              font-size: 14px;
              padding-left: 48px;
            }
          }
          .grey-border {
            border: 2px solid #f1f1f1 !important;
          }
          .code-group {
            border:  none;
            .code {
              border: 2px solid #eee;
              float: left;
              width: 160px;
              border-radius: 5px;
              box-sizing: border-box;
              padding-left: 18px;
            }
            .img-code {
              width: 126px;
              height: 34px;
              margin: 0 0 0 20px;
              border: 2px solid #eee;
              border-radius: 5px;
              float: left;
              box-sizing: border-box;
              img {
                position: static;
                width: 100%;
                height: 100%;
                display: block;
                cursor: pointer;
              }
            }
          }
          .login-btn-grop {
            height: 38px;
            width: 306px;
            background-color: #ff8532;
            margin: 50px auto 0;
            line-height: 38px;
            color: #fff;
            border-radius: 5px;
            cursor: pointer;
            font-size: 18px;
          }
          .link-group {
            height: 44px;
            font-size: 14px;
            line-height: 44px;
            color: #999;
          }
        }
        .login-aside {
          flex: 1;
        }
      }
      .login-aside {
        line-height: 22px;
        padding: 100px 0 0 200px;
        text-align: left;
        p {
          height: 22px;
          font-size: 18px;
          color: #323232;
        }
        .active {
          color: #ff8532;
          padding: 0 0 0 0;
          cursor: auto;
          text-align: left;
        }
      }
    }
  }
  .main-ft {
    height: 32px;
    line-height: 32px;
    font-size: 12px;
    color: #ccc;
    background-color: #fff;
    text-align: center;
    border-top: 1px solid #ccc;
  }
}